function m(e){var o,n,a,d,u,t,i;if(!e||!((o=e.nodes)!=null&&o.length))return;const c=Array.from(document.querySelectorAll("[data-key-feature-id]"));for(const l of c){const p=l.dataset.keyFeatureId,r=e.nodes.find(s=>(s==null?void 0:s.id)===p);r&&(l.classList.remove("is-loading"),l.innerHTML=`
      <span class="product-card__meta-icon">
        <img
          src="${((a=(n=r==null?void 0:r.fields)==null?void 0:n.find(s=>s.key==="icon"))==null?void 0:a.reference.image.url)??""}"
          alt="${((u=(d=r==null?void 0:r.fields)==null?void 0:d.find(s=>s.key==="title"))==null?void 0:u.value)??""}"
          width="16"
          height="16"
        />
      </span>
      <span
        class="product-card__meta-label text-s">
        ${((i=(t=r==null?void 0:r.fields)==null?void 0:t.find(s=>s.key==="title"))==null?void 0:i.value)??""}
      </span>
    `)}}function g(e){var o,n,a;if(!e||!((o=e.nodes)!=null&&o.length))return;const c=Array.from(document.querySelectorAll("[data-bundle-collection]"));for(const d of c){const u=d.dataset.bundleCollection,t=e.nodes.find(i=>(i==null?void 0:i.id)===u);if((t==null?void 0:t.__typename)!=="Collection")return;if(!(!t||!((a=(n=t==null?void 0:t.products)==null?void 0:n.nodes)!=null&&a.length))){d.innerHTML="";for(const i of t.products.nodes.slice(0,3))d.innerHTML+=`
        <div class="product-card__meta-feature product-card__meta-feature--bundle">
          <span class="product-card__meta-icon">
          ${window.themeVars.icons.plus}
          </span>
          <span
            class="product-card__meta-label text-s">
            ${i.title}
          </span>
        </div>
      `;t.products.nodes.length>3&&(d.innerHTML+=`
        <div class="product-card__meta-feature product-card__meta-feature--bundle">
          <span class="product-card__meta-icon">
          ${window.themeVars.icons.plus}
          </span>
          <span class="product-card__meta-label text-s">
            ${t.products.nodes.length-3} ${window.themeVars.strings.collection.productCard.more}
          </span>
        </div>
      `)}}}function h(){const e=Array.from(document.querySelectorAll("sc-comparison-table"));if(!e.length)return;const c=`
    .view-more-button, .product .button {
      background-color: var(--color-brand-secondary);
      font-size: var(--font-size-sm);
      font-weight: var(--weight-book);
      border: none;
      border-radius: var(--spacing-l);
    }

    .attribute-name, .group-name {
      font-size: var(--spacing-s);
    }

    .attribute-value {
      font-size: var(--spacing-sm);
      font-weight: var(--weight-book);
    }

    .column-header .product :nth-child(1) {
     order: 1;
    }

    .column-header .product :nth-child(2) {
     order: 0;
    }

    .column-header .product :nth-child(3) {
     order: 2;
    }

    .column-header .product :nth-child(4) {
     order: 3;
    }

    .product__info .product__title {
      display: none;
    }

    .product__info .money {
      font-weight: var(--weight-semi);
    }

    .product__image-wrapper {
      max-width: calc(100% - 64px);
      margin: 0 auto;
      mix-blend-mode: multiply;
      background-color: var(--color-background);

      img {
        mix-blend-mode: multiply;
      }
    }

    .product .rating-badge {
      display: none;
    }

    .product {
      align-items: center;
    }
    
    .product__select {
      appearance: none;
      background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none"%3E%3Cpath d="M5.5 7.5L10.5 12.5L15.5 7.5" stroke="%2325282A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E') no-repeat right 0.5rem center;
      border-color: #D8D8D8;
      font-weight: var(--weight-semi);
      height: fit-content;
      padding: var(--spacing-s);
    }

    @media(max-width: 863px) {
      .product__title {
        font-size: var(--font-size-sm);
      }

      .product__price {
       font-size: var(--font-size-sm);
       font-weight: var(--weight-book);
      }

      .rating-count {
        font-size: var(--spacing-xs);
      }
      
      td.cell {
        padding-inline-end: var(--spacing-s) !important;
      }
      
      tr.row {
        padding-block: var(--spacing-m) !important;
      }
      
      .product__select {
        width: 80%;
        margin: 0 auto;
        border: 0;
        border-radius: 0;
        border-bottom: 1px solid #D8D8D8;
        height: fit-content;
        padding-inline-end: 1.5rem;
      }
    }

    @media(min-width: 864px) {
      tr.row {
        padding-inline: var(--spacing-m);
      }

      tr.row--attribute {
        min-height: 72px;
        align-items: center;
      }

      tr.row--attribute .row-header {
        background-color: transparent;
      }

      tr.row--attribute:nth-child(odd) {
        background-color: var(--color-grey-200);
      }

      thead th, tbody td {
        padding-inline: var(--spacing-l) !important;
      }

      .attribute-name, .attribute-value {
        font-size: var(--font-size-m);
      }

      .attribute-name {
        font-weight: var(--weight-semi);
      }

      .product__select {
        font-size: var(--font-size-m);
      }

      .product .button {
        font-size: var(--font-size-m);
        padding: 0;
        height: 44px;
        padding-inline: var(--spacing-xl);
        font-weight: var(--weight-mid);
        margin-block-start: var(--spacing-m);
      }
    }
  `;for(const o of e){const n=o.shadowRoot;if(n){const a=document.createElement("style");a.textContent=c,n.appendChild(a)}}}function f(e,c=!1){const{cartDeliveryEstimatedShipping:o,cartDeliveryDateEstimate:n}=window.themeVars.strings.cart,a=new Date(e).getTime(),d=new Date().getTime(),u=a-d,t=Math.round(Math.floor(u/(1e3*60*60*24))/7),i=t<=1?t:t-1,l=t+1,p=`${o}: ${i} - ${l} ${window.themeVars.strings.cart.weeks}`;if(c)return l<1?n:`${p} ${window.themeVars.strings.cart.weeks}`;if(u>0){const r=`${o}: ${n}`;return t<1?r:p}}export{h as a,m as b,f as s,g as u};
